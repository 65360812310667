(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/init-scripts.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/init-scripts.js');
"use strict";

var _svs$widgets;

const {
  multiImageStyleElement,
  setLazyConfig
} = svs.content.image_v2.helpers;
const logger = new svs.core.log.Logger('image_v2:init-scripts');
const {
  getUrlWithCorrectHost
} = Handlebars.helpers;
const config = {
  options: svs.core.config.data.svsconfig
};

setLazyConfig();

const widgetDataByCmsId = Object.keys(((_svs$widgets = svs.widgets) === null || _svs$widgets === void 0 ? void 0 : _svs$widgets.data) || {}).reduce((acc, key) => {
  const widgetArea = svs.widgets.data[key];
  if (Array.isArray(widgetArea)) {
    widgetArea.forEach(widget => {
      if (widget.data && widget.data.cms && widget.data.cms.id && widget.data.images) {
        acc[widget.data.cms.id] = widget.data.images;
      }
    });
  }
  return acc;
}, {});

const multiImageElements = document.querySelectorAll('[data-multi-image-id]');
multiImageElements.forEach(imageElement => {
  multiImageStyleElement.setupForImageElement(imageElement);

  const currentId = imageElement.closest('[data-cmsid]').dataset.cmsid;
  const images = widgetDataByCmsId[currentId] && typeof widgetDataByCmsId[currentId] === 'object' ? Object.keys(widgetDataByCmsId[currentId]).map(key => widgetDataByCmsId[currentId][key]) : [];
  if (images) {
    const pictureElement = imageElement.querySelector('picture');
    const imgElement = pictureElement.querySelector('img');
    if (imgElement) {
      const currentSrc = imgElement.currentSrc;
      const matchingImage = images.find(img => currentSrc.indexOf(img.url.split('/').slice(-3).join('/')) !== -1);
      if (matchingImage) {
        matchingImage.altText && imgElement.setAttribute('alt', matchingImage.altText);
        matchingImage.title && imgElement.setAttribute('title', matchingImage.title);
      }
    }
  }
});
function handleLoad(element) {
  const onloadCallbackPath = element.dataset.imageV2Onload;
  if (onloadCallbackPath) {
    try {
      const properties = onloadCallbackPath.split('.');
      const callbackFunction = properties.reduce((obj, property) => {
        if (obj && obj[property]) {
          return obj[property];
        }
        logger.warn("Property ".concat(property, " not found in ").concat(onloadCallbackPath, "."));
        return null;
      }, self);
      if (typeof callbackFunction === 'function') {
        callbackFunction(element);
      } else {
        logger.warn("".concat(onloadCallbackPath, " is not a function."));
      }
    } catch (error) {
      logger.warn("Error executing onload callback: ".concat(error));
    }
  }
}

function handleError(element, currentSrc) {
  const fallbackImagePath = element.dataset.imageV2Fallbackimage;
  const fallbackImageUrl = getUrlWithCorrectHost(fallbackImagePath, config);
  if (fallbackImagePath) {
    const fallbackImage = element.cloneNode(true);
    fallbackImage.src = fallbackImageUrl;
    fallbackImage.setAttribute('is-fallback-image', 'true');
    element.parentElement.parentElement.appendChild(fallbackImage);
    element.parentElement.remove();
  } else {
    logger.warn('Error loading image:', currentSrc);
  }
}
document.addEventListener('DOMContentLoaded', () => {
  const imageElements = document.querySelectorAll('[data-image-v2-onload], [data-image-v2-fallbackimage]');
  imageElements.forEach(element => {
    const {
      currentSrc
    } = element;
    if (currentSrc) {
      const image = new Image();
      image.src = currentSrc;
      image.addEventListener('load', () => {
        handleLoad(element);
      });
      image.addEventListener('error', () => {
        handleError(element, currentSrc);
      });
    }
  });
});

 })(window);